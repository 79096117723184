import React, { useEffect, useState } from "react"
import Title from "../components/shared-title"
import useStores from "../hooks/use-stores"
import { observer } from "mobx-react";
import { Link, navigate } from "gatsby";
import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import VmButton from "../components/shared-button";
import { isBrowser, Language, NavListByCategory, ProdEnvCheck } from "../constants/options";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { t } from "i18next";

const ResetPasswordPage = observer(() => {
  const { userStore, rootStore } = useStores();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [cPassword, setCPassword] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [profile, setProfile] = useState<any>();

  useEffect(() => {
    if (userStore.isAuthed && userStore.userToken !== "" && localStorage.USER_TOKEN) {
      if (ProdEnvCheck([localStorage.MENU])) navigate(NavListByCategory[localStorage.MENU][0] ? NavListByCategory[localStorage.MENU][0].link : `/${localStorage.MENU.toLowerCase()}`);
      else navigate("/dashboard");
    } else if (ProdEnvCheck([localStorage.USER_TOKEN])) {
      userStore.getStudentProfile()
        .then((res) => setProfile(res));
    }
  }, []);

  const sendResetPasswordEmail = () => {
    setErrorMsg("");
    if (email === "") return;
    userStore.sendResetPasswordEmail({ email })
      .then(() => {
        rootStore.notify(t('VERIFICATION_EMAIL_SENT'), 'success');
        navigate("/");
      }).catch((err) => {
        setErrorMsg(t('EMAIL_NOT_EXIST'));
      });
  }

  const onResetPassword = () => {
    if (password !== cPassword) {
      rootStore.notify(t('TWO_PASSWORDS_NOT_MATCH'), 'warning');
      return;
    }
    profile.password = password;
    userStore.updateStudentProfile(profile)
      .then(() => {
        rootStore.notify(t('PASSWORD_UPDATED'), 'success')
        localStorage.removeItem("USER_TOKEN");
        navigate("/");
      }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'error'));
  }

  return (
    <>
      <Title pageName={t('RESET_PASSWORD')} />
      <div className="top-0 left-0 right-0 bottom-0 h-screen relative bg-theme">
        <div
          className="absolute top-1/2 left-1/2"
          style={{ transform: 'translate(-50%, -50%)', width: 'fit-content' }}
        >
          {
            userStore.isAuthed && userStore.userToken !== "" && localStorage.USER_TOKEN ?
              <div className="bg-white p-8 rounded-lg">
                <Typography variant="h6">{t('VALIDATING_USER_CREDENTIAL')}...</Typography>
              </div>
              :
              <div
                className={`relative border border-white overflow-auto shadow-2xl ${isBrowser && ProdEnvCheck([localStorage.USER_TOKEN]) && "w-80"}`}
                style={{ background: "rgba(255, 255, 255, 0.3)" }}
              >
                <div className="bg-white m-4 relative">
                  {
                    // Reset Password UI
                    isBrowser && ProdEnvCheck([localStorage.USER_TOKEN]) ?
                      <div className="px-4 py-10 border border-l-0">
                        <div className="text-2xl text-theme text-center mb-6">{t('RESET_PASSWORD_NOW')}</div>
                        <div className="my-4">
                          <TextField
                            value={password}
                            label={t('NEW_PASSWORD')}
                            sx={{ width: "100%" }}
                            variant="outlined"
                            type="password"
                            onChange={(value) => setPassword(value.target.value)}
                          />
                          <TextField
                            value={cPassword}
                            label={t('CONFIRM_PASSWORD')}
                            sx={{ marginY: 2, width: "100%" }}
                            variant="outlined"
                            type="password"
                            onChange={(value) => setCPassword(value.target.value)}
                          />
                        </div>
                        <div className="text-center">
                          {userStore.loading ? <CircularProgress className="text-theme" />
                            : <VmButton
                              className="bg-theme text-white w-full py-2"
                              style={{ height: 'fit-content' }}
                              onClick={onResetPassword}
                            >
                              {t('RESET_NOW')}
                            </VmButton>
                          }
                          {errorMsg !== "" && <Typography color="red">{errorMsg}</Typography>}
                        </div>
                      </div>
                      :
                      // Send Reset Password Email UI
                      <div className="p-8 py-10 border border-l-0">
                        <div className="text-2xl text-theme text-center mb-4">{t('RESET_PASSWORD_NOW')}</div>
                        <div className={`text-gray-400 text-sm text-center mb-8 ${isBrowser && ProdEnvCheck([localStorage.LANG]) && localStorage.LANG !== Language.ENGLISH && 'px-10'}`}>
                          {t('ENTER_YOUR_EMAIL')}
                        </div>
                        <div className="my-4">
                          <TextField
                            value={email}
                            label={t('YOUR_EMAIL')}
                            variant="outlined"
                            className="w-full"
                            onChange={(value) => setEmail(value.target.value)}
                          />
                        </div>
                        <div className="text-center mb-4">
                          {userStore.loading ? <div className="py-4"><CircularProgress className="text-theme" /></div>
                            : <VmButton
                              className="bg-theme text-white w-full py-2"
                              style={{ height: 'fit-content' }}
                              onClick={sendResetPasswordEmail}
                            >
                              {t('SEND_EMAIL')}
                            </VmButton>
                          }
                          {errorMsg !== "" && <Typography color="red">{errorMsg}</Typography>}
                        </div>
                        <Link to="/" className="text-theme text-sm text-center">
                          <Typography sx={{ textDecoration: "underline", marginTop: -1 }}>{t('BACK_TO_LOGIN')}</Typography>
                        </Link>
                      </div>
                  }

                </div>
              </div>
          }
        </div>
      </div>
    </>
  )
})

export default ResetPasswordPage;
